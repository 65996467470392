import * as type from 'constants/banners'
import { shouldBannerBeHidden } from 'helpers/banners'

export const initialState = {
  banners: []
}

const addBanner = (bannersState, newBanner) => {
  if (shouldBannerBeHidden({ id: newBanner.id })) {
    return bannersState
  }

  // if banner id exists, replace with new banner to avoid adding duplicate
  const bannerIndex = bannersState.findIndex(
    banner => banner.id === newBanner.id
  )

  if (bannerIndex !== -1) {
    const updatedBannersState = [...bannersState]
    updatedBannersState[bannerIndex] = newBanner
    return updatedBannersState
  }

  // add new banner
  return [...bannersState, newBanner]
}

function banners(state = initialState, action) {
  switch (action.type) {
    case type.ADD_BANNER:
      return {
        ...state,
        banners: addBanner(state.banners, action.payload)
      }
    case type.REMOVE_BANNER:
      return {
        ...state,
        banners: [...state.banners].filter(
          banner => banner.id !== action.payload
        )
      }
    default:
      return state
  }
}

export default banners
