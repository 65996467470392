import * as type from 'constants/action_types/alerts'
import { ALERT_FILTER_KEYS } from 'constants/alerts'

export const initialState = {
  currentPage: 0,
  alerts: [],
  alertsFetch: {
    isLoading: false
  },
  moreAlerts: {
    isLoading: false
  },
  deleteAlert: {
    isLoading: false,
    completed: false
  },
  activeAlert: {},
  lastUnreadAlertId: null,
  lastViewedAlertId: null,
  showLoadMore: true,
  filters: {
    asin: '',
    source: '',
    keys: ALERT_FILTER_KEYS
  }
}

function alerts(state = initialState, action) {
  switch (action.type) {
    case type.PENDING_ALERTS_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: true
        }
      }
    case type.RESET_ALERTS_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: false
        }
      }
    case type.SET_ALERTS_DATA:
      return {
        ...state,
        ...action.payload
      }
    case type.RESET_ALERTS_FILTER:
      return {
        ...state,
        filters: {
          ...initialState.filters
        }
      }
    case type.RESET_ALERTS: {
      return {
        ...initialState
      }
    }
    default:
      return state
  }
}

export default alerts
