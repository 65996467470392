import * as type from '../constants/action_types/card_info'

export const initialState = {
  stripe_last4: '',
  stripe_card_type: '',
  can_remove_card: null,
  exp_month: null,
  exp_year: null,
  payment_method: '',
  billing_name: null,
  billing_address: {},
  cardInfoFetch: {
    isLoading: false
  }
}

function cardInfo(state = initialState, action) {
  switch (action.type) {
    case type.PENDING_CARD_INFO_ACTION:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isLoading: true
        }
      }
    case type.SET_CARD_INFO:
      return {
        ...state,
        ...action.payload,
        cardInfoFetch: {
          isLoading: false
        }
      }
    default:
      return state
  }
}

export default cardInfo
