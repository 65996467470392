import * as type from 'constants/analytics'

export const initialState = {
  identifyRequests: [],
  inProgress: false
}

function analytics(state = initialState, action) {
  switch (action.type) {
    case type.ADD_IDENTIFY_REQUEST:
      const identifyRequests = state.identifyRequests.slice()
      identifyRequests.push(action.payload)
      return {
        ...state,
        identifyRequests
      }
    case type.REMOVE_IDENTIFY_REQUEST:
      const requests = state.identifyRequests.slice()
      requests.shift()
      return {
        ...state,
        identifyRequests: requests
      }
    case type.IDENTIFY_REQUESTS_IN_PROGRESS:
      return {
        ...state,
        inProgress: action.payload
      }
    default:
      return state
  }
}

export default analytics
