import * as type from 'constants/action_types'

export const initialState = {
  isOpen: false,
  createReportParams: undefined
}

function chatbot(state = initialState, action) {
  switch (action.type) {
    case type.SET_CHATBOT_STATE:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}

export default chatbot
